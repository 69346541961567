<template>
  <!-- 案例与服务 -->
  <div class="case">
    <div class="title">
      <div class="word">
        <div style="border-bottom: 3px solid #060606">案例</div>
        <div>与服务</div>
      </div>
      <div class="more" @click="goToProduct">
        <div>了解更多案例</div>
        <div style="margin-top: 6px;"><i class="el-icon-right" /></div>
      </div>
    </div>
    <div class="content">
      <div class="item">
        <div><img style="width: 100%;" src="../assets/case/simulation.jpg" /></div>
        <div class="theme">
          <div class="kind">虚拟仿真</div>
          <div class="line"></div>
        </div>
        <div class="intr">通过高度逼真的数字化场景提供安全、经济、可控的实验和研究环境，助力客户提升技能和效率</div>
      </div>
      <div class="item" style="margin-top: 80px;">
        <div><img style="width: 100%;" src="../assets/case/equipment.jpg" /></div>
        <div class="theme">
          <div class="kind">定制化专用设备</div>
          <div class="line"></div>
        </div>
        <div class="intr">摩鸣拥有多年非标自动化设备定制经验，在生产线自动化与包装流水线方面，也拥有丰富的自动化设备研发经验</div>
      </div>
      <div class="item">
        <div><img style="width: 100%;" src="../assets/case/manufacture.jpg" /></div>
        <div class="theme">
          <div class="kind">智能制造</div>
          <div class="line"></div>
        </div>
        <div class="intr">我们的智能制造业务致力于提升企业的生产效率和产品质量，通过引入人工智能、物联网技术，实现生产流程的智能化和自动化</div>
      </div>
      <div class="item" style="margin-top: 80px;">
        <div><img style="width: 100%;" src="../assets/case/twin.jpg" /></div>
        <div class="theme">
          <div class="kind">数字孪生</div>
          <div class="line"></div>
        </div>
        <div class="intr">通过搭建与现实世界物体、流程和系统相对应的虚拟模型，实现实时监控、优化分析和智能决策，提升企业的运营效率</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  methods: {
    goToProduct() {
      window.location.href = "http://mmkj.mominger.com:78/successCase"
    }
  },
}
</script>

<style lang="less" scoped>
.case {
  width: 1200px;
  height: 600px;
  margin-top: 60px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .word {
      font-size: 32px;
      font-weight: 600;
      color: #060606;
      display: flex;
      height: 60px;
    }
    .more {
      width: 130px;
      height: 18px;
      color: #060606;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      box-shadow: 1px 1px 1px #dad9d9;
      transition-duration: 0.2s;
    }
    .more:hover {
      background-color: #5EB4E6;
      color: #FFFFFF;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    .item {
      width: 280px;
      height: 470px;
      background-color: #F8F8F8;
      transition-duration: 0.4s;
      .theme {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        .kind {
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          color: #060606;
        }
        .line {
          width: 50px;
          height: 2px;
          background-color: #060606;
          margin-top: 8px;
        }
      }
      .intr {
        margin: 20px 25px 0;
        text-align: justify;
        font-size: 16px;
        color: #060606;
        line-height: 26px
      }
    }
    .item:hover {
      transform: scale(1.1);
    }
  }
}
</style>
