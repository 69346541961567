<template>
  <!-- 底部 -->
  <div class="bottom">
    <div class="top-box">
      <div class="contact-box">
        <div class="qr-box">
          <div><img src="../../assets/bottom/qr_code.png" /></div>
          <div style="margin-top: 10px;">微信扫码关注</div>
          <div>企业公众号</div>
        </div>
        <div class="details-box">
          <div class="phone">
            <img src="../../assets/bottom/phone.png" />
            <div style="margin-left: 10px;">027-5930-3092</div>
          </div>
          <div class="address">
            <img src="../../assets/bottom/address.png" />
            <div style="margin-left: 10px;">
              <div>武汉市东湖新技术开发区光谷三路777-9号</div>
              <div>东湖综合保税区-云启自贸创新港1号楼9层</div>
            </div>
          </div>
          <div class="link">
            <img src="../../assets/bottom/link.png" />
            <div style="margin-left: 10px;">WWW.MOMINGER.COM</div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <div class="content-box">
          <div class="title">产品与服务</div>
          <div class="content-detail">虚拟仿真</div>
          <div class="list-top-between content-detail">数字孪生</div>
          <div class="list-top-between content-detail">自动化设备</div>
          <div class="list-top-between content-detail">智能制造</div>
        </div>
        <div class="content-box">
          <div class="title">资源与支持</div>
          <div class="content-detail">客户案例</div>
          <div class="list-top-between content-detail">渠道合作</div>
          <div class="list-top-between content-detail">服务支持</div>
        </div>
        <div class="content-box">
          <div class="title">了解摩鸣</div>
          <div class="content-detail">企业介绍</div>
          <div class="list-top-between content-detail">团队力量</div>
          <div class="list-top-between content-detail">成就展示</div>
        </div>
      </div>
    </div>
    <div class="base-box">
      <div class="copyright">© 2023 武汉摩鸣科技有限公司 版权所有 <span @click="open" style="cursor: pointer;">鄂ICP备18014157号-3</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  data () {
    return {}
  },
  methods: {
    open() {
      window.open('https://beian.miit.gov.cn', '_blank');
    },
  },
}
</script>

<style lang="less" scoped>
.bottom {
  width: 1200px;
  .top-box {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    height: 250px;
    border-bottom: 1px solid hsla(0,0%,100%,.1);
    .contact-box {
      display: flex;
      justify-content: space-between;
      width: 600px;
      .qr-box {
        color: #FFFFFF;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .details-box {
        margin-top: 5px;
        .phone {
          display: flex;
          align-items:center;
          color: #FFFFFF;
          font-size: 14px;
        }
        .address {
          display: flex;
          align-items: center;
          color: #FFFFFF;
          font-size: 14px;
          margin-top: 30px;
        }
        .link {
          display: flex;
          align-items: center;
          color: #FFFFFF;
          font-size: 14px;
          margin-top: 30px;
        }
      }
    }
    .right-box {
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      width: 450px;
      .content-box {
        font-size: 14px;
        .title {
          font-size: 20px;
          margin-bottom: 39px;
        }
        .list-top-between {
          margin-top: 20px;
        }
        .content-detail {
          cursor: pointer;
        }
        .content-detail:hover{
          color: #5EB4E6;
        }
      }
    }
  }
  .base-box {
    .copyright {
      font-size: 12px;
      color: #aab1b8;
      margin-top: 18px;
    }
  }
}
</style>
