<template>
  <div class="top">
    <div>
      <img src="../../assets/组 1.png" />
    </div>
    <div class="category">
      <div class="child" @click="goToMain">首页</div>
      <div class="child" @click="goToProduct">产品与服务</div>
      <div class="child" @click="goToSolution">行业解决方案</div>
      <div class="child" @click="goToSuccessCase">成功案例</div>
      <div class="child-link" 
        v-for="(item, index) in tabList"
        :key="index"
        @mouseover="
          item.name == '平台链接'
            ? (item.isVisibleMenuBox = true)
            : (item.isVisibleMenuBox = false)
        "
        @mouseleave="
          item.isVisibleMenuBox = false;
          isMenuHover = -1;
        ">
        <span>{{ item.name }}</span>
        <div class="menu" v-show="item.isVisibleMenuBox">
          <div class="menu-box">
            <div class="menu-box-cascader">
              <div
                class="menu-item"
                v-for="(menuItem, menuIndex) in menuList"
                :key="menuIndex"
                :class="{ 'menu-hover': menuIndex == isMenuHover }"
                @mouseover="handleMenuMouseOver(menuItem, menuIndex)"
                @mouseleave="handleMenuMouseLeave()"
                @click="handleMenuClick(menuItem)"
              >
                <span @click.prevent>{{ menuItem.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="child" @click="goToContact">联系我们</div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'Top',
  data () {
    return {
      tabList: [
        {
          name: '平台链接',
          isVisibleMenuBox: false,
        },
      ],
      menuList: [
        {
        label: '数字化办公平台',
        link: 'https://login.dingtalk.com/oauth2/challenge.htm?client_id=suitenac0egsefz58gyrl&response_type=code&scope=openid%2Bcorpid&prompt=consent&state=3ac1787c5dec18f710c3ca7&redirect_uri=https%3A%2F%2Fwww.jiandaoyun.com%2Fdingtalk%2Fcorp%2Fauth%3Fredirect_uri%3D%252Fdashboard'
        },
        {
          label: '综合资源管理平台',
          link: 'http://mmkj.mominger.com:68/#/'
        },
        {
          label: '模型素材展示平台',
          link: 'http://mmkj.mominger.com:75/#/'
        },
      ],
      // 菜单索引:高亮
      isMenuHover: -1,
      isVisibleSubMenuBox: false,
    }
  },
  methods: {
    goToMain() {
      window.location.href = "http://www.mominger.com/"
    },
    goToProduct() {
      window.location.href = "http://mmkj.mominger.com:78/"
    },
    goToSuccessCase() {
      window.location.href = "http://mmkj.mominger.com:78/successCase"
    },
    goToSolution() {
      window.location.href = "http://mmkj.mominger.com:78/solution"
    },
    goToContact() {
      window.location.href = "http://mmkj.mominger.com:78/contact"
    },
    handleMenuMouseOver(menuItem, menuIndex) {
      this.isMenuHover = menuIndex;
    },
    handleMenuMouseLeave() {
      this.isMenuHover = -1;
    },
    handleMenuClick(menuItem)  {
      window.open(menuItem.link, '_blank');
    }
  },
}
</script>

<style lang="less" scoped>
.top {
  height: 120px;
  width: 100%;  
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .category {
    // width: 485px;
    width: 545px;
    display: flex;
    justify-content: space-between;
    color: #060606;
    font-size: 16px;
    .child {
      cursor: pointer;
    }
    .child-link {
      cursor: pointer;
      .menu {
        position: absolute;
        top: 71px;
        right: 2px;
        z-index: 10;
        width: 240px;
        height: auto;
        padding-top: 10px;
        .menu-box {
          position: relative;
          display: flex;
          width: 100%;
          height: auto;
          .menu-box-cascader {
            width: 100%;
            height: auto;
            border-radius: 5px;
            box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.15);
            display: block;
            // &:nth-of-type(2) {
            //   position: absolute;
            //   top: 0;
            //   left: 152px;
            //   width: 180px;
            //   // background-color: #faf;
            // }
 
            .menu-item {
              position: relative;
              width: 100%;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-color: #ECECEC;
              border-radius: 3px;
              span {
                font-size: 16px;
                color: #060606;
                height: 16px;
                font-family: MicrosoftYaHei;
                line-height: 17px;
              }
 
              // .el-icon-arrow-right {
              //   font-size: 12px;
              //   position: absolute;
              //   right: 2px;
              // }
 
              &:hover {
                background-color: #0068F7;
 
                span, .el-icon-arrow-right {
                  color: #ffffff;
                }
              }
            }
 
            .menu-hover {
              background-color: #0068F7;
 
              span, .el-icon-arrow-right {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .child:hover {
      color: #5EB4E6;
    }
    .child-link:hover {
      color: #5EB4E6;
    }
  }
}
// .top::before {  
// }
.dropdown {
  .dropdown-title {
    display: inline-block;
    position: relative;
    cursor: pointer;
    .arrow {
      width: 8px;
      height: 8px;
      margin-left: 5px;
    }
    .arrow:hover {
      color: #5EB4E6;
    }
  }
  .dropdown-content {
    // 定位显示局域
    position: absolute;
    visibility: hidden; // 隐藏
    opacity: 0; // 隐藏
    transition: all 0.6s ease-in-out;
    background-color: #FFFFFF;
    width: 110px;
    .dropdown-menu {
      margin-top: 4px;  // 与title制造距离
      padding: 15px 0px 0px 10px; // 给下面多留一点距离，抵消视觉差
      color: white;
      border-radius: 4px;
      .menuItem {
        width: 100%;  // 自适应宽度
        white-space: nowrap;
        padding: 10px 10px;
        font-size: 14px;
        color: #060606;
        cursor: pointer;
        border-radius: 4px;
        text-align: left;
        &:hover {
          color: #5EB4E6;
        }
      }
    }
  }
  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
}
</style>
