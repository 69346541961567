<template>
  <!-- 关于摩鸣 -->
  <div class="about">
    <div class="title">
      <div class="word">
        <div style="border-bottom: 3px solid #FFFFFF">关于</div>
        <div>摩鸣</div>
      </div>
      <div class="intr-container">
        <div class="top-between">武汉摩鸣科技有限公司，成立于2018年，是一家专注于软硬件技术应用研发和信息技术服务的高新技术企业。公司技术团队由中国地质大学（武汉）数字化虚拟技术实验室研究人员发展而来，利用前沿的虚拟仿真技术、卓越的自主创新能力和完善的管理理念，以满足客户需求为核心追求，为客户提供可靠、高效、智能的运营解决方案和定制化服务。</div>
        <div class="top-between">公司服务内容包含教育实训、智慧城市、工业制造等，积累了丰富的定制化方案服务经验。公司业务涵盖机械设计、结构仿真、自动化控制、嵌入式设计开发、装备信息化、智能制造、三维仿真技术、XR(扩展现实)应用、物联网和虚拟现实技术数字孪生系统应用等多个应用领域。</div>
        <div class="top-between">公司的客户群体众多且稳定，包括研究院所、重点高校、国有企业、上市公司和高新技术企业等；公司已与中国地质大学（武汉）、武汉理工大学、华中科技大学、中建国际和中交二航等知名机构建立了长期的合作关系。</div>
      </div>
    </div>
    <div class="certificate-container">
      <div style="margin-right: 5px;">
        <div><img class="img-box-half" src="../assets/about/3A.png" /></div>
        <div><img class="img-box-half" src="../assets/about/high-tech.png" /></div>
      </div>
      <div><img class="img-box" src="../assets/about/infosec.png" /></div>
      <div><img class="img-box" src="../assets/about/qs.png" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.about {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    .word {
      font-size: 32px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      height: 60px;
    }
    .intr-container {
      color: #FFFFFF;
      font-size: 16px;
      width: 550px;
      text-align: justify;
      text-indent: 2em;
      line-height: 26px;
      .top-between {
        margin-top: 20px;
      }
    }
  }
  .certificate-container {
    display: flex;
    align-items: center;
    margin-top:60px;
    .img-box-half {
      width: 200px;
      height: 142px;
    }
    .img-box {
      width: 200px;
      height: 300px;
    }
  }
}
</style>
