<template>
  <!-- 合作客户 -->
  <div class="client">
    <div class="title-box">
      <div class="content">合作客户</div>
      <div class="line"></div>
    </div>
    <div class="partner-box">
      <div class="img-box">
        <div><img class="img" src="../assets/client/01.png" /></div>
        <div><img class="img" src="../assets/client/02.png" /></div>
        <div><img class="img" src="../assets/client/03.png" /></div>
        <div><img class="img" src="../assets/client/04.png" /></div>
      </div>
      <div class="img-box" style="margin-top: 10px;">
        <div><img class="img" src="../assets/client/05.png" /></div>
        <div><img class="img" src="../assets/client/06.png" /></div>
        <div><img class="img" src="../assets/client/07.png" /></div>
        <div><img class="img" src="../assets/client/08.png" /></div>
      </div>
      <div class="img-box" style="margin-top: 10px;">
        <div><img class="img" src="../assets/client/09.png" /></div>
        <div><img class="img" src="../assets/client/10.png" /></div>
        <div><img class="img" src="../assets/client/11.png" /></div>
        <div><img class="img" src="../assets/client/12.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Client',
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.client {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      font-size: 32px;
      font-weight: 600;
      color: #060606;
    }
    .line {
      height: 3px;
      width: 70px;
      background-color: #060606;
      margin-top: 14px;
    }
  }
  .partner-box {
    width: 100%;
    margin-top: 70px;
    .img-box {
      display: flex;
      justify-content: space-between;
      .img {
        width: 270px;
        transition-duration: 0.2s;
      }
      .img:hover {
        transform: scale(1.07);
      }
    }
  }
}
</style>
