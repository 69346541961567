<template>
  <div class="home">
    <div class="top-container">
      <Top></Top>
    </div>
    <div class="slide">
      <el-carousel>
        <el-carousel-item>
          <img class="img-container" src="../assets/banner_1.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="img-container" src="../assets/banner_2.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="img-container" src="../assets/banner_3.jpg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="aim">
      <div class="content" style="">数字智能化解决方案及应用服务商</div>
    </div>
    <div class="case-container">
      <Case></Case>
    </div>
    <div class="about-container">
      <About></About>
    </div>
    <div>
      <Idea></Idea>
    </div>
    <div class="client-container">
      <Client></Client>
    </div>
    <div class="bottom-container">
      <Bottom></Bottom>
    </div>
    <div>
      <!-- <el-backtop /> -->
      <Backtop></Backtop>
    </div>
  </div>
</template>

<script>
import Top from '@/components/top/Top'
import Case from '@/components/Case'
import About from '@/components/About'
import Idea from '@/components/Idea'
import Client from '@/components/Client'
import Bottom from '@/components/bottom/Bottom'
import Backtop from '@/components/BackTop.vue'

export default {
  name: 'HomeView',
  components: {
    Top,
    Case,
    About,
    Idea,
    Client,
    Bottom,
    Backtop
  }
}
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 1100;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 1px #dad9d9;
  }
  .slide {
    width: 100%;
    height: 600px;
    margin-top: 120px;
    .img-container {
      height: 600px;
      width: 100%;
    }
  }
  .aim {
    height: 50px;
    width: 100%;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 1200px;
      color: #060606;
      font-size: 16px;
    }
  }
  .about-container {
    width: 100%;
    height: 700px;
    margin-top: 200px;
    background-image: url(../assets/about/firm_intr.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .client-container {
    width: 100%;
    height: 600px;
    margin-top: 60px;
    background-image: url(../assets/client/bg.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
  .bottom-container {
    width: 100%;
    height: 380px;
    background-color: #001537;
    display: flex;
    justify-content: center;
  }
}
/deep/ .el-carousel__container {
  height: 600px;
}
</style>
