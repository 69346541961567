var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auto"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('el-backtop',{staticClass:"fixed-backTop bottom-0",attrs:{"visibility-height":600}},[_c('div',{staticClass:"custom-backTop"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/icon/返回 拷贝.png")}}),_c('span',{staticClass:"text"},[_vm._v("回到顶部")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-backTop1 bottom-4 cur"},[_c('div',{staticClass:"custom-backTop"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/icon/服务热线.png")}}),_c('span',{staticClass:"text"},[_vm._v("服务热线")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-call bottom-4"},[_c('div',{staticClass:"custom-call"},[_c('div',{staticClass:"call-text"},[_vm._v("027-5930 3092")]),_c('div',{staticClass:"call-text"},[_vm._v("027-5930 3093")]),_c('div',{staticClass:"call-text"},[_vm._v("027-5930 3094")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-backTop2 bottom-3 cur"},[_c('div',{staticClass:"custom-backTop"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/icon/商务合作.png")}}),_c('span',{staticClass:"text"},[_vm._v("商务微信")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-business bottom-3"},[_c('div',{staticClass:"custom-business"},[_c('img',{attrs:{"src":require("../assets/backtop/彭小冬微信.png")}}),_c('div',{staticClass:"business-text"},[_vm._v("商务合作/项目沟通")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-backTop3 bottom-2 cur"},[_c('div',{staticClass:"custom-backTop"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/icon/微信.png")}}),_c('span',{staticClass:"text"},[_vm._v("公众号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-WHMM bottom-2"},[_c('div',{staticClass:"custom-business"},[_c('img',{attrs:{"src":require("../assets/backtop/WHMM公众号.png")}}),_c('div',{staticClass:"business-text"},[_vm._v("了解更多/最新动态")])])])
}]

export { render, staticRenderFns }