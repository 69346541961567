<template>
  <!-- 企业理念 -->
  <div class="idea">
    <div class="title">
      <div class="word">
        <div style="border-bottom: 3px solid #060606">企业</div>
        <div>理念</div>
      </div>
    </div>
    <div class="philosophy-box">
      <div class="content-box">
        <div class="mflow-box-container">
          <div class="mflow-box" style="">
            <div><img style="position: absolute; z-index: 8; top: 12px; left: 12px" src="../assets/idea/经营理念.png" /></div>
          </div>
        </div>
        <!-- <div><img src="../assets/idea/manage.png" /></div> -->
        <div class="content-title">经营理念</div>
        <div class="content-en">PHILOSOPHY</div>
        <div class="content-zh">创新 专业</div>
        <div class="content-zh m-top5">品质 服务</div>
      </div>
      <div class="content-box">
        <div class="mflow-box-container">
          <div class="mflow-box" style="">
            <div><img style="position: absolute; z-index: 8; top: 12px; left: 12px" src="../assets/idea/服务理念.png" /></div>
          </div>
        </div>
        <!-- <div><img src="../assets/idea/service.png" /></div> -->
        <div class="content-title">服务理念</div>
        <div class="content-en">SERVICE IDEA</div>
        <div class="content-zh">客户至上</div>
        <div class="content-zh m-top5">合作共赢</div>
      </div>
      <div class="content-box">
        <div class="mflow-box-container">
          <div class="mflow-box" style="">
            <div><img style="position: absolute; z-index: 8; top: 12px; left: 12px" src="../assets/idea/人才理念.png" /></div>
          </div>
        </div>
        <!-- <div><img src="../assets/idea/talent.png" /></div> -->
        <div class="content-title">人才理念</div>
        <div class="content-en">TALENT CONCEPT</div>
        <div class="content-zh">
          唯才是命，爱才之德，
        </div>
        <div style="font-size: 30px; color: #5D5D5D; font-family: MicrosoftYaHei; margin-top: 5px;">
          识才之能，用才之长。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Idea',
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.idea {
  .title {
    width: 1200px;
    margin-top: 30px;
    .word {
      font-size: 32px;
      font-weight: 600;
      color: #060606;
      display: flex;
      height: 60px;
    }
  }
  .philosophy-box {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    .content-box {
      width: 360px;
      height: 270px;
      background-color: #F8F8F8;
      // text-align: center;
      padding: 70px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-title {
        font-size: 20px;
        color: #060606;
        font-weight: 600;
        z-index: 1;
      }
      .content-en {
        font-size: 32px;
        color: #EBEBEB;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        position: relative;  
        transform: translateY(-18px);
        z-index: 0;
      }
      .content-zh {
        font-size: 30px;
        color: #5D5D5D;
        font-family: MicrosoftYaHei;
      }
      .m-top5 {
        margin-top: 5px;
      }
    }
  }
}
.mflow-box-container {
  position: relative;  
  transform: translateY(-20px);
}
.mflow-box {
  width: 120px;
  height: 120px;
  // background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  // margin-bottom: 20px;
}
.mflow-box::before {
  content: '';
  width: 110px;
  height: 110px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
}
.mflow-box::after {
  content: '';
  width: 300%;
  height: 300%;
  background-color: #ffffff;
  background-image: conic-gradient(#ffffff, #5EB4E6 100%);
  border-radius: 50%;
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: 1;
  animation: rotate 4s linear infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
