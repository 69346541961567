<template>
  <div class="auto">
    <div class="fixed-backTop1 bottom-4 cur">
      <div class="custom-backTop">
        <img class="icon" src="../assets/icon/服务热线.png" />
        <span class="text">服务热线</span>
      </div>
    </div>
    <div class="fixed-call bottom-4">
      <div class="custom-call">
        <div class="call-text">027-5930 3092</div>
        <div class="call-text">027-5930 3093</div>
        <div class="call-text">027-5930 3094</div>
      </div>
    </div>
    <div class="fixed-backTop2 bottom-3 cur">
      <div class="custom-backTop">
        <img class="icon" src="../assets/icon/商务合作.png" />
        <span class="text">商务微信</span>
      </div>
    </div>
    <div class="fixed-business bottom-3">
      <div class="custom-business">
        <img src="../assets/backtop/彭小冬微信.png" />
        <div class="business-text">商务合作/项目沟通</div>
      </div>
    </div>
    <div class="fixed-backTop3 bottom-2 cur">
      <div class="custom-backTop">
        <img class="icon" src="../assets/icon/微信.png" />
        <span class="text">公众号</span>
      </div>
    </div>
    <div class="fixed-WHMM bottom-2">
      <div class="custom-business">
        <img src="../assets/backtop/WHMM公众号.png" />
        <div class="business-text">了解更多/最新动态</div>
      </div>
    </div>
    <!-- <div class="fixed-backTop bottom-1 cur">
      <div class="custom-backTop">
        <img class="icon" src="../assets/icon/链接.png" />
        <span class="text">平台链接</span>
      </div>
    </div> -->
    <el-backtop :visibility-height="600" class="fixed-backTop bottom-0">
      <div class="custom-backTop">
        <img class="icon" src="../assets/icon/返回 拷贝.png" />
        <span class="text">回到顶部</span>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'BackTop',
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.auto {
  overflow: auto;
  .fixed-call {
    width: 243px;
    height: 165px;
    background: #0068F7;
    // box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
    /* 位置设置 */  
    position: fixed;
    right: 90px !important;
    top: 270px !important;
    // transform: translateY(-40%);
    z-index: 888;
    display: none;
    padding-right: 1px;
    .custom-call {
      margin: 23px 0 0 29px;
      line-height: 40px;
    }
    .call-text {
      font-size: 24px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .fixed-business {
    width: 167px;
    height: 187px;
    background: #0068F7;
    // box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
    /* 位置设置 */  
    position: fixed;
    right: 90px !important;
    top: 325px !important;
    // transform: translateY(-40%);
    z-index: 888;
    display: none;
    .custom-business {
      margin: 22px 0 0 21px;
    }
    .business-text {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      margin-left: 4px;
    }
  }
  .fixed-WHMM {
    width: 167px;
    height: 187px;
    background: #0068F7;
    // box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
    /* 位置设置 */  
    position: fixed;
    right: 90px !important;
    top: 405px !important;
    // transform: translateY(-40%);
    z-index: 888;
    display: none;
    .custom-business {
      margin: 22px 0 0 21px;
    }
    .business-text {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      margin-left: 4px;
    }
  }
  .fixed-backTop {
    width: 64px;
    height: 64px;
    background: #0068F7;
    box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
 
    /* 位置设置 */  
    position: fixed;  
    right: 20px !important;
    // transform: translateY(-40%);
    z-index: 999;  
  }
  .fixed-backTop1 {
    width: 64px;
    height: 64px;
    background: #0068F7;
    box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
    /* 位置设置 */ 
    position: fixed;  
    right: 20px !important;  
    // transform: translateY(-40%);
    z-index: 999;
  }
  .fixed-backTop2 {
    width: 64px;
    height: 64px;
    background: #0068F7;
    box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
 
    /* 位置设置 */  
    position: fixed;  
    right: 20px !important;  
    // transform: translateY(-40%);
    z-index: 999;
  }
  .fixed-backTop3 {
    width: 64px;
    height: 64px;
    background: #0068F7;
    box-shadow: 0px 0px 90px 0px #474747;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #145FC9;
    opacity: 1;
 
    /* 位置设置 */  
    position: fixed;  
    right: 20px !important;  
    // transform: translateY(-40%);
    z-index: 999;
  }
  // .fixed-backTop4 {
  //   width: 64px;
  //   height: 64px;
  //   background: #0068F7;
  //   box-shadow: 0px 0px 90px 0px #474747;
  //   border-radius: 5px 5px 5px 5px;
  //   border: 1px solid #145FC9;
  //   opacity: 1;
  //   /* 位置设置 */  
  //   position: fixed;  
  //   right: 20px !important;  
  //   // transform: translateY(-40%);
  //   z-index: 999;
  // }
  
  .custom-backTop {
    display: flex;  
    flex-direction: column;  
    align-items: center;  
    justify-content: center; 
    color: white;
    font-size: 14px;
  }
  .icon {
    width: 25px;
    margin-top: 9px;
  }
  .text {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    margin-top: 9px;
  }
  .fixed-backTop1:hover ~ .fixed-call {
    display: block;
  }
  .fixed-call:hover {
    display: block;
  }
  .fixed-backTop2:hover ~ .fixed-business {
    display: block;
  }
  .fixed-business:hover {
    display: block;
  }
  .fixed-backTop3:hover ~ .fixed-WHMM {
    display: block;
  }
  .fixed-WHMM:hover {
    display: block;
  }
  .cur {
    cursor: pointer;
  }
  .bottom-0 {
    // bottom: 24% !important;
    bottom: 33% !important;
  }
  // .bottom-1 {
  //   bottom: 33% !important;
  // }
  .bottom-2 {
    bottom: 42% !important;
  }
  .bottom-3 {
    bottom: 51% !important;
  }
  .bottom-4 {
    bottom: 60% !important;
  }
}
</style>
